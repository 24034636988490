import { createReducer } from 'deox';
import { produce } from 'immer';
import { CheckoutTypes } from './types';
import { CheckoutActions } from './actions';

const initialState: CheckoutTypes = {
  billingAddress: {
    first_name: '',
    last_name: '',
    company: '',
    country: '',
    street: '',
    address: '',
    title: '',
    apartment: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    note: ''
  },
  shippingAddress: {
    first_name: '',
    last_name: '',
    company: '',
    country: '',
    street: '',
    address: '',
    title: '',
    apartment: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    note: ''
  },
  errors: [],
  termsAndConditions: false,
  paymentMethod: 'cod',
  redirect: false,
  paymentLink: undefined,
  loadingCheckout: false,
  loadingAddresses: false,
  loadingCart: false,
  loadingCalculate: false,
  calculate: {
    message: undefined,
    subtotal: '',
    discount: undefined,
    item_total: undefined,
    delivery_fee: '',
    total: '',
    description: ''
  },
  loginModal: false,
  loginModalPassword: '',
  loginModalUsername: '',
  loadingLogin: false,
  message: '',
  loadingCheckoutStatus: false,
  checkoutStatus: undefined,
  offers: [],
  loadingOffers: false
};

export const checkoutReducer = createReducer(initialState, handleAction => [
  // Terms & Conditions
  handleAction(CheckoutActions.setTermsAndConditions, (state, { payload }) =>
    produce(state, draft => {
      draft.termsAndConditions = payload
    }),
  ),
  // Billing Address
  handleAction(CheckoutActions.billingAddress.set, (state, { payload }) =>
    produce(state, draft => {
      draft.billingAddress = payload;
    }),
  ),
  handleAction(CheckoutActions.billingAddress.clear, (state) =>
    produce(state, draft => {
      draft.billingAddress = initialState.billingAddress;
    }),
  ),
  // Shipping Address
  handleAction(CheckoutActions.shippingAddress.set, (state, { payload }) =>
    produce(state, draft => {
      draft.shippingAddress = payload;
    }),
  ),
  handleAction(CheckoutActions.shippingAddress.clear, (state) =>
    produce(state, draft => {
      draft.shippingAddress = initialState.shippingAddress;
    }),
  ),
  // Validate
  handleAction(CheckoutActions.validate, (state) =>
    produce(state, draft => {
      // Errors
      var errors : string[] = []

    
      // Validating billing address
      const billing_address = draft.billingAddress;
      if(billing_address.first_name.length == 0) {
        errors.push('Billing First name is a required field.');
      }
      if(billing_address.last_name.length == 0) {
        errors.push('Billing Last name is a required field.');
      }
      if(billing_address.street.length == 0) {
        errors.push('Billing Street address is a required field.');
      }
      if(billing_address.city.length == 0) {
        errors.push('Billing Town / City is a required field.');
      }
      if(billing_address.phone.length == 0) {
        errors.push('Billing Phone is a required field.');
      }
      if(billing_address.email.length == 0) {
        errors.push('Billing Email address is a required field.');
      }
      
      // Validating shipping address
      const shipping_address = draft.shippingAddress;
      if(shipping_address.first_name.length == 0) {
        errors.push('Shipping First name is a required field.');
      }
      if(shipping_address.last_name.length == 0) {
        errors.push('Shipping Last name is a required field.');
      }
      if(shipping_address.street.length == 0) {
        errors.push('Shipping Street address is a required field.');
      }
      if(shipping_address.city.length == 0) {
        errors.push('Shipping Town / City is a required field.');
      }
      if(shipping_address.phone.length == 0) {
        errors.push('Shipping Phone is a required field.');
      }
      if(shipping_address.email.length == 0) {
        errors.push('Shipping Email address is a required field.');
      }

      // Terms and Conditions
      if(!draft.termsAndConditions) {
        errors.push('Please read and accept the terms and conditions to proceed with your order.')
      }

      if(draft.calculate.offer && !draft.calculate.offer_valid) {
        errors.push(draft.calculate.offer_message ?? 'Offer is not valid')
      }

      draft.errors = errors
    }),
  ),
  // Checkout
  handleAction(CheckoutActions.checkout.request, (state) =>
    produce(state, draft => {
      // Errors
      var errors : string[] = []

    
      // Validating billing address
      const billing_address = draft.billingAddress;
      if(billing_address.first_name.length == 0) {
        errors.push('Billing First name is a required field.');
      }
      if(billing_address.last_name.length == 0) {
        errors.push('Billing Last name is a required field.');
      }
      if(billing_address.street.length == 0) {
        errors.push('Billing Street address is a required field.');
      }
      if(billing_address.city.length == 0) {
        errors.push('Billing Town / City is a required field.');
      }
      if(billing_address.phone.length == 0) {
        errors.push('Billing Phone is a required field.');
      }
      if(billing_address.email.length == 0) {
        errors.push('Billing Email address is a required field.');
      }
      
      // Validating shipping address
      const shipping_address = draft.shippingAddress;
      if(shipping_address.first_name.length == 0) {
        errors.push('Shipping First name is a required field.');
      }
      if(shipping_address.last_name.length == 0) {
        errors.push('Shipping Last name is a required field.');
      }
      if(shipping_address.street.length == 0) {
        errors.push('Shipping Street address is a required field.');
      }
      if(shipping_address.city.length == 0) {
        errors.push('Shipping Town / City is a required field.');
      }
      if(shipping_address.phone.length == 0) {
        errors.push('Shipping Phone is a required field.');
      }
      if(shipping_address.email.length == 0) {
        errors.push('Shipping Email address is a required field.');
      }

      // Terms and Conditions
      if(!draft.termsAndConditions) {
        errors.push('Please read and accept the terms and conditions to proceed with your order.')
      }

      if(draft.calculate.offer && !draft.calculate.offer_valid) {
        errors.push(draft.calculate.offer_message ?? 'Offer is not valid')
      }

      draft.errors = errors
      draft.loadingCheckout = (errors.length == 0);
    }),
  ),
  handleAction(CheckoutActions.checkout.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingCheckout = false
      if(payload) {
        draft.paymentLink = payload
      } else {
        draft.paymentLink = undefined;
      }
      draft.redirect = true
    }),
  ),
  handleAction(CheckoutActions.checkout.fail, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingCheckout = false;
      draft.redirect = false;
      draft.loadingLogin = false
      draft.message = payload

      var errors : string[] = []
      errors.push(payload);
      draft.errors = errors;
    }),
  ),
  handleAction(CheckoutActions.redirect, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingCheckout = false
      draft.redirect = payload
    }),
  ),

  // Addresses
  handleAction(CheckoutActions.addresses.request, (state ) =>
    produce(state, draft => {
      console.log('requesting orders');
      draft.loadingAddresses = true;
    }),
  ),
  handleAction(CheckoutActions.addresses.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingAddresses = false;
      if(payload) {
        if(payload.billing_address) {
          draft.billingAddress = payload.billing_address
        }
        if(payload.shipping_address) {
          draft.shippingAddress = payload.shipping_address
        }
      }
    }),
  ),
  handleAction(CheckoutActions.addresses.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingAddresses = false;
    }),
  ),

  // Cart
  handleAction(CheckoutActions.cart.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingCart = true;
    }),
  ),
  handleAction(CheckoutActions.cart.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingCart = false;
    }),
  ),
  handleAction(CheckoutActions.cart.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingCart = false;
    }),
  ),

  // Calculate
  handleAction(CheckoutActions.calculate.request, (state) =>
    produce(state, draft => {
      draft.loadingCalculate = true;
    }),
  ),
  handleAction(CheckoutActions.calculate.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingCalculate = false;
      draft.calculate = payload
      if(draft.calculate.message) {
        if((payload.message ?? '').length > 0) {
          draft.errors = [payload.message!!]
        }
      }
    }),
  ),
  handleAction(CheckoutActions.calculate.fail, (state) =>
    produce(state, draft => {
      draft.loadingCalculate = false;
    }),
  ),

  // Setting payment method
  handleAction(CheckoutActions.setPaymentMethod, (state, { payload }) =>
    produce(state, draft => {
      draft.paymentMethod = payload;
    }),
  ),

  handleAction(CheckoutActions.loginModal, (state, { payload }) =>
    produce(state, draft => {
      draft.loginModal = payload;
      draft.loginModalUsername = '';
      draft.loginModalPassword = '';
    }),
  ),

  handleAction(CheckoutActions.setLoginModalPassword, (state, { payload }) =>
    produce(state, draft => {
      draft.loginModalPassword = payload;
    }),
  ),

  handleAction(CheckoutActions.setLoginModalUsername, (state, { payload }) =>
    produce(state, draft => {
      draft.loginModalUsername = payload;
    }),
  ),

  handleAction(CheckoutActions.reset, (state) =>
    produce(state, draft => {
      draft.loginModal = false;
      draft.errors = [];
      draft.redirect = false;
      draft.paymentLink = undefined;
      draft.loadingCheckout = false;
      draft.loadingLogin = false;
      draft.loadingOffers = false;
    }),
  ),

  // Checkout Status
  handleAction(CheckoutActions.checkoutStatus.request, (state, { payload } ) =>
    produce(state, draft => {
      draft.loadingCheckoutStatus = true;
      draft.checkoutStatus = undefined
    }),
  ),
  handleAction(CheckoutActions.checkoutStatus.success, (state, { payload } ) =>
    produce(state, draft => {
      draft.loadingCheckoutStatus = false;
      draft.checkoutStatus = payload
    }),
  ),
  handleAction(CheckoutActions.checkoutStatus.fail, (state ) =>
    produce(state, draft => {
      draft.loadingCheckoutStatus = false;
      draft.checkoutStatus = undefined
    }),
  ),

  // Offers
  handleAction(CheckoutActions.offers.request, (state) =>
    produce(state, draft => {
      draft.loadingOffers = true;
    }),
  ),
  handleAction(CheckoutActions.offers.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingOffers = false;
      draft.offers = payload;
    }),
  ),
  handleAction(CheckoutActions.offers.fail, (state) =>
    produce(state, draft => {
      draft.loadingOffers = false;
    }),
  ),
  handleAction(CheckoutActions.setSelectedOffer, (state, { payload }) =>
    produce(state, draft => {
      draft.selectedOffer = payload;
    }),
  ),
  handleAction(CheckoutActions.removeSelectedOffer, (state) =>
    produce(state, draft => {
      draft.selectedOffer = undefined;
    }),
  ),
]);
