import { useSelector } from "react-redux";
import { CartModel } from "../../../server/models/cart"
import { OfferModel } from "../../../server/models/offer"
import { useAction } from "../../../utils/hooks/action";
import { CheckoutActions } from "../module/actions";
import { RootState } from "../../../store/rootState";

export const OfferItem = (props: { offer: OfferModel }) => {
    const {
        selectedOffer,
        loadingCalculate,
        calculate
    } = useSelector((state: RootState) => state.checkout);
    const setSelectedOffer = useAction(CheckoutActions.setSelectedOffer);
    const removeSelectedOffer = useAction(CheckoutActions.removeSelectedOffer);

    const handleApplyOffer = () => {
        if (loadingCalculate) {
            return;
        }

        if((props.offer.id === calculate?.offer?.id)) {
            removeSelectedOffer()
        } else {
            setSelectedOffer(props.offer)
        }
    };

    return (
        <div style={{ background: "#ececec" }}>
            <tr className="cart_item d-flex align-items-center ps-3 pe-3">
                <td className="product-details">
                    <div className="product-name">
                        {props.offer.name}
                    </div>
                    <div className="product-description">
                        <strong>{props.offer.description}</strong>
                    </div>
                </td>
                <button
                    type="button"
                    className="button ps-3 pe-3 pt-1 pb-1"
                    name="apply_coupon"
                    value="Apply offer"
                    onClick={handleApplyOffer}
                    style={{ border: '1px solid black' }}
                ><div>{(props.offer.id === calculate?.offer?.id) ? ("Remove") : "Apply Offer"}</div></button>
            </tr>
            {calculate?.offer_message && <div className="ps-4 pe-4" style={{
                background: calculate?.offer_valid ? "green" : "red",
                color: "white"
            }}>{calculate?.offer_message}</div>}
        </div>
    )
}