import { createAction } from 'deox';
import { AddressModel } from '../../../server/models/address';
import { AddressesModel } from '../../../server/models/addresses';
import { CalculateModel } from '../../../server/models/calculate';
import { ProductModel } from '../../../server/models/product';
import { CalculatePayload } from '../../../server/payload/calculate';
import { LoginRequestPayload } from '../../../server/payload/login';
import { CheckoutStatusModel } from '../../../server/models/checkout_status';
import { OfferModel } from '../../../server/models/offer';


export class CheckoutActions {
  static billingAddress = {
    set: createAction('checkout/set_billing_address', resolve => (payload: AddressModel) =>
      resolve(payload),
    ),
    clear: createAction('checkout/clear_billing_address')
  };

  static shippingAddress = {
    set: createAction('checkout/set_shipping_address', resolve => (payload: AddressModel) =>
      resolve(payload),
    ),
    clear: createAction('checkout/clear_shipping_address')
  };

  static checkout = {
    request: createAction('checkout/checkout_request', resolve => (payload?: LoginRequestPayload) => resolve(payload)),
    success: createAction('checkout/checkout_success', resolve => (paymentLink?: string) => resolve(paymentLink)),
    fail: createAction('checkout/checkout_fail', resolve => (payload: string) => resolve(payload)),
  };

  static addresses = {
    request: createAction('checkout/addresses_request'),
    success: createAction('checkout/addresses_success', resolve => (payload: AddressesModel) =>
      resolve(payload),
    ),
    fail: createAction('checkout/addresses_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static cart = {
    request: createAction('checkout/cart_request'),
    success: createAction('checkout/cart_success', resolve => (payload: { products: ProductModel[] }) =>
      resolve(payload),
    ),
    fail: createAction('checkout/cart_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static calculate = {
    request: createAction('checkout/calculate_request', resolve => (payload: CalculatePayload) =>
      resolve(payload),
    ),
    success: createAction('checkout/calculate_success', resolve => (payload: CalculateModel) =>
      resolve(payload),
    ),
    fail: createAction('checkout/calculate_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static offers = {
    request: createAction('checkout/offers_request'),
    success: createAction('checkout/offers_success', resolve => (payload: OfferModel[]) =>
      resolve(payload),
    ),
    fail: createAction('checkout/offers_failure', resolve => (error: any) =>
      resolve(error),
    )
  };

  static setSelectedOffer = createAction('checkout/set_selected_offer', resolve => (payload: OfferModel) =>
    resolve(payload),
  );
  static removeSelectedOffer = createAction('checkout/remove_selected_offer');

  static checkoutStatus = {
    request: createAction('checkout/order_request', resolve => (payload: string) =>
      resolve(payload),
    ),
    success: createAction('checkout/order_success', resolve => (payload: CheckoutStatusModel) =>
      resolve(payload),
    ),
    fail: createAction('checkout/order_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };


  static redirect = createAction('checkout/redirect', resolve => (payload: boolean) => resolve(payload));
  static setTermsAndConditions = createAction('checkout/set_terms_and_conditions', resolve => (payload: boolean) => resolve(payload));
  static setPaymentMethod = createAction('checkout/set_payment_method', resolve => (payload: 'cod' | 'bank' | 'tabby') => resolve(payload));
  static loginModal = createAction('checkout/login_modal', resolve => (payload: boolean) => resolve(payload));
  static setLoginModalUsername = createAction('checkout/set_login_modal_username', resolve => (payload: string) => resolve(payload));
  static setLoginModalPassword = createAction('checkout/set_login_modal_password', resolve => (payload: string) => resolve(payload));

  static validate = createAction('checkout/validate');
  static reset = createAction('checkout/reset');
}
