import { useEffect, useState } from "react"
import { Footer } from "../../../components/footer"
import { Header } from "../../../components/header/headers"
import { TitleWithDivider } from "../../../components/title_with_divider"
import { MyAccountMenu } from "../components/menu"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/rootState"
import { useAction } from "../../../utils/hooks/action"
import { MyAccountActions } from "../../../modules/my_account/actions"
import { AddressModel } from "../../../server/models/address"
import { InputField } from "../../../components/input_field"
import { Button } from "../../../components/button"
import { useNavigate, useLocation } from 'react-router-dom';
import { BasePage } from "../../base/base"

export const EditBillingAddressPage = () => {
    // Navigation
    const location = useLocation();
    const navigate = useNavigate();
    // States
    const { addresses } = useSelector((state: RootState) => state.myAccount);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [apartment, setApartment] = useState<string>('');
    const [town, setTown] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const { moveToAddresses } = useSelector((state: RootState) => state.myAccount);

    // Actions
    const putBillingAddressAction = useAction(MyAccountActions.putBillingAddress.request);
    const setMoveToAddressesTrue = useAction(MyAccountActions.moveToAddresses.true);
    const setMoveToAddressesFalse = useAction(MyAccountActions.moveToAddresses.false);


    useEffect(() => {
        if (addresses && addresses.billing_address) {
            // setFirstName(addresses.billing_address.first_name)
            // setLastName(addresses.billing_address.last_name)
            // setCompany(addresses.billing_address.company)
            // setCountry('United Arab Emirates')
            // setStreet(addresses.billing_address.street)
            // setApartment(addresses.billing_address.apartment)
            // setTown(addresses.billing_address.city)
            // setState(addresses.billing_address.state)
            // setPhone(addresses.billing_address.phone)
            // setEmail(addresses.billing_address.email)
        }
    }, [])

    useEffect(() => {
        console.log(`move? ${moveToAddresses}`)
        if (moveToAddresses) {
            setMoveToAddressesFalse()
            navigate('/my_account/addresses', { replace: true })
        }
    }, [moveToAddresses])

    return (
        <BasePage>
            <div className="d-flex flex-column">
                <Header />
                <div className="font40 p-4 pb-5">Addresses</div>
                <div className="container d-flex mb-5">
                    <div className="row w-100">
                        <div className="col-lg-3 col-sm-12">
                            <MyAccountMenu />
                        </div>
                        <div className="col-lg-9 col-sm-12 font14 container d-flex flex-column justify-content-start align-items-start" style={{ textAlign: 'start' }}>
                            <TitleWithDivider title={'Billing Address'} />
                            <div className="mb-4" />
                            <div className="row w-100">
                                <div className="col-lg-6 col-sm-12">
                                    <InputField
                                        title={'First Name'}
                                        required={true}
                                        onChange={(value: string) => {
                                            setFirstName(value)
                                        }} />
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <InputField
                                        title={'Last Name'}
                                        required={true}
                                        onChange={(value: string) => {
                                            setLastName(value)
                                        }} />
                                </div>
                            </div>

                            <InputField
                                title={'Company name (optional)'}
                                required={false}
                                onChange={(value: string) => {
                                    setCompany(value)
                                }} />
                            <InputField
                                title={'Country / Region'}
                                required={true}
                                disabled
                                value="United Arab Emirates"
                                onChange={(value: string) => {
                                    setCountry(value)
                                }} />
                            <InputField
                                title={'Street Address'}
                                required={true}
                                onChange={(value: string) => {
                                    setStreet(value)
                                }} />
                            <InputField
                                title={'Apartment'}
                                required={true}
                                onChange={(value: string) => {
                                    setApartment(value)
                                }} />
                            <InputField
                                title={'Town / City'}
                                required={true}
                                onChange={(value: string) => {
                                    setTown(value)
                                }} />
                            <InputField
                                title={'State / Country (optional)'}
                                required={false}
                                onChange={(value: string) => {
                                    setState(value)
                                }} />
                            <InputField
                                title={'Phone'}
                                required={true}
                                onChange={(value: string) => {
                                    setPhone(value)
                                }} />
                            <InputField
                                title={'Email address'}
                                required={true}
                                onChange={(value: string) => {
                                    setEmail(value)
                                }} />
                            <div className="mb-2" />
                            <Button title={'Save address'} onClick={() => {
                                putBillingAddressAction({
                                    first_name: firstName,
                                    last_name: lastName,
                                    company: company,
                                    country: country,
                                    street: street,
                                    address: '',
                                    title: '',
                                    apartment: apartment,
                                    city: town,
                                    state: state,
                                    phone: phone,
                                    email: email,
                                })
                            }} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </BasePage>
    )
}