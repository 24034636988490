import { useSelector } from "react-redux";
import { Footer } from "../../../components/footer"
import { Header } from "../../../components/header/headers"
import { RootState } from "../../../store/rootState";
import { MyAccountMenu } from "../components/menu"
import { useAction } from "../../../utils/hooks/action";
import { MyAccountActions } from "../../../modules/my_account/actions";
import { useEffect } from "react";
import { Alert } from "../../../components/alert";
import { OrderActions } from "./module/actions";
import { ProductModel } from "../../../server/models/product";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { Base } from "../../base/components/base";
import { BasePage } from "../../base/base";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/loading";


export const OrderPage = () => {
    // Url params
    let params = useParams();
    // States
    const { order, products, loadingOrder, billingAddress, shippingAddress } = useSelector((state: RootState) => state.order);
    // Actions
    const orderAction = useAction(OrderActions.order.request);
    const { t } = useTranslation();

    useEffect(() => {
        // Fetch orders
        console.log('params');
        console.log(params);
        orderAction((params as any).id);
    }, [params])

    if (loadingOrder) {
        return <Loading/>
    }

    const HeaderNav = () => {
        return <nav style={{textAlign:'start'}} className="container woocommerce-breadcrumb"><a href="https://hiphonetelecom.com">{t('home')}</a><span className="delimiter"><i className="fa fa-angle-right"></i></span><a>{t('my_account')}</a><span className="delimiter"><i className="fa fa-angle-right"></i></span>{t('order')} #{params.id}</nav>
    }

    const ProductItem = (props: { product: ProductModel }) => {
        return (
            <tr className="woocommerce-table__line-item order_item">
                <td className="woocommerce-table__product-name product-name">
                    <a href={`/product/${props.product?.slug}`}>{props.product.name_en}</a> <strong className="product-quantity">×&nbsp;{+props.product.order_quantity}</strong>	</td>
                <td className="woocommerce-table__product-total product-total">
                    <span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">{t('aed')}</span>&nbsp;{+props.product.sale_price * +props.product.order_quantity}</bdi></span>	</td>
            </tr>
        )
    }

    return (
        <BasePage>
        <div className="d-flex flex-column">
            <Header />
            <HeaderNav/>
            <div className="font40 p-4 pb-5 d-flex justify-content-center">{t('order')} #{params.id}</div>
            <div className="container mb-5">
                <div className="row">
                    {/* Menu */}
                    <div className="col-lg-3 col-12">
                        <MyAccountMenu />
                    </div>
                    {/* Content */}
                    <div className="col-lg-9 col-12" style={{ textAlign: 'start' }}>
                        <div className="woocommerce-MyAccount-content">
                            <div className="woocommerce-notices-wrapper"></div><p>{t('order')} #<mark className="order-number">{params.id}</mark> was placed on <mark className="order-date"><Moment>{order.date}</Moment></mark> and is currently <mark className="order-status">{order.status}</mark>.</p>
                            <section className="woocommerce-order-details">
                                <h2 className="woocommerce-order-details__title">{t('order_details')}</h2>
                                <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">

                                    <thead>
                                        <tr>
                                            <th className="woocommerce-table__product-name product-name">{t('product')}</th>
                                            <th className="woocommerce-table__product-table product-total">{t('total')}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {[
                                        ...products.map((product, index) => <ProductItem product={product}/>
                                    )]}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <th scope="row">{t('subtotal')}:</th>
                                            <td><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{t('aed')}</span>&nbsp;{+order.subtotal}</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{t('shipping')}:</th>
                                            <td>
                                                { (order.delivery_fee != '0') && <span className="woocommerce-Price-amount amount">
                                                    <span className="woocommerce-Price-currencySymbol">{t('aed')}</span>
                                                    <div>&nbsp;{+order.delivery_fee}</div>
                                                </span>}
                                                { (order.delivery_fee == '0') && <span className="woocommerce-Price-amount amount">
                                                    <div className="bold" style={{color: 'green'}}>{t('free')}</div>
                                                </span>}
                                            {/* <small className="shipped_via">via Flat rate</small> */}
                                            </td>
                                        </tr>
                                        { order.discount && <tr>
                                            <th scope="row">{t('discount')}:</th>
                                            <td style={{color:'green'}}>{t('aed')} {order.discount}</td>
                                        </tr>}
                                        <tr>
                                            <th scope="row">{t('payment_method')}:</th>
                                            <td>{order.payment_method == 'cash' ? 'Cash on delivery' : order.payment_method}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{t('total')}:</th>
                                            <td><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{t('aed')}</span>&nbsp;{order!.total}</span> <small className="includes_tax">(includes <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{t('aed')}</span>&nbsp;0.05</span> VAT)</small></td>
                                        </tr>
                                    </tfoot>
                                </table>

                            </section>

                            <section className="woocommerce-customer-details">
                                <section className="woocommerce-columns woocommerce-columns--2 woocommerce-columns--addresses col2-set addresses">
                                    <div className="woocommerce-column woocommerce-column--1 woocommerce-column--billing-address col-1">
                                        <h2 className="woocommerce-column__title">{t('billing_address')}</h2>
                                        <address>
                                            {billingAddress.first_name + ' ' + billingAddress.last_name}<br />{billingAddress.company}<br />{billingAddress.country}<br />{billingAddress.street}<br />{billingAddress.city}<br/>{billingAddress.country}
                                            <p className="woocommerce-customer-details--phone">{billingAddress.phone}</p>
                                            <p className="woocommerce-customer-details--email">{billingAddress.email}</p>
                                        </address>
                                    </div>

                                    <div className="woocommerce-column woocommerce-column--2 woocommerce-column--shipping-address col-2">
                                        <h2 className="woocommerce-column__title">{t('shipping_address')}</h2>
                                        <address>
                                        {shippingAddress.first_name + ' ' + shippingAddress.last_name}<br />{shippingAddress.company}<br />{shippingAddress.country}<br />{shippingAddress.street}<br />{shippingAddress.city}<br/>{shippingAddress.country}
                                            <p className="woocommerce-customer-details--phone">{shippingAddress.phone}</p>
                                            <p className="woocommerce-customer-details--email">{shippingAddress.email}</p>
                                        </address>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </BasePage>
    )
}