import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { CheckoutCartItem } from "./checkout_cart_item";
import { useAction } from "../../../utils/hooks/action";
import { CheckoutActions } from "../module/actions";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { OfferItem } from "./offer_item";
import { OfferModel } from "../../../server/models/offer";

export const YourOrder = () => {
  const {
    termsAndConditions: terms_and_conditions,
    calculate,
    loginModal,
    paymentMethod,
    errors,
    offers
  } = useSelector((state: RootState) => state.checkout);
  const { cart, login } = useSelector((state: RootState) => state.app);
  const setTermsAndConditions = useAction(
    CheckoutActions.setTermsAndConditions
  );
  const setPaymentMethod = useAction(CheckoutActions.setPaymentMethod);
  const requestCheckoutAction = useAction(CheckoutActions.checkout.request);
  const loginModalAction = useAction(CheckoutActions.loginModal);
  const validateAction = useAction(CheckoutActions.validate);

  const { t } = useTranslation();
  const [operating, setOperating] = useState(false);

  function checkout() {
    setOperating(true);
    validateAction();
  }

  useEffect(() => {
    if (operating) {
      setOperating(false);
      if (errors.length == 0) {
        if (!login) {
          loginModalAction(true);
        } else {
          requestCheckoutAction();
        }
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [errors, operating]);

  return (
    <div className="order-review-wrapper">
      <h3 id="order_review_heading_v2">{t('Your order')}</h3>
      <div id="order_review" className="woocommerce-checkout-review-order">
        <table
          className="shop_table woocommerce-checkout-review-order-table"
          style={{ position: "static", zoom: 1 }}
        >
          <thead>
            <tr>
              <th className="product-name">{t('Offers')}</th>
              {/* <th className="product-total">Subtotal</th> */}
            </tr>
            <tbody>
              {[...offers.map((item, index) => <OfferItem offer={item} />)]}
            </tbody>
          </thead>

          <thead>
            <tr>
              <th className="product-name">{t('product')}</th>
              {/* <th className="product-total">Subtotal</th> */}
            </tr>
          </thead>
          <tbody>
            {[...cart.map((item, index) => <CheckoutCartItem cart={item} />)]}
          </tbody>
          <tfoot>
            <tr className="cart-subtotal">
              <th>{t('subtotal')}</th>
              <td>
                <span className="woocommerce-Price-amount amount">
                  <bdi>
                    <span className="woocommerce-Price-currencySymbol">
                      AED
                    </span>
                    &nbsp;{calculate.subtotal}
                  </bdi>
                </span>
              </td>
            </tr>

            {calculate.discount && (
              <tr className="cart-subtotal">
                <th>{t("discount")}</th>
                <td data-title="Discount">
                  <bdi className="" style={{ color: "green" }}>
                    <span className="woocommerce-Price-currencySymbol">
                      {t("aed")}
                    </span>
                    &nbsp;-{calculate.discount}
                  </bdi>
                </td>
              </tr>
            )}

            {calculate.offer_valid && (
              <tr className="cart-subtotal">
                <div>
                <th className="pb-0">{t("offer")}</th>
                <div className="mb-1">{calculate.offer?.name}</div>
                </div>
                <td data-title="Discount">
                  <bdi className="" style={{ color: "green" }}>
                    <span className="woocommerce-Price-currencySymbol">
                      {t("aed")}
                    </span>
                    &nbsp;{calculate.offer?.price}
                  </bdi>
                </td>
              </tr>
            )}

            {/* {calculate.item_total && <tr className="cart-subtotal">
                            <th>{t('Items total')}</th>
                            <td data-title="Discount">
                                <bdi >
                                    <span className="woocommerce-Price-currencySymbol">
                                        {t('aed')}
                                    </span>
                                    &nbsp;{calculate.item_total}
                                </bdi>
                            </td>
                        </tr>} */}

            <tr className="cart-subtotal">
              <th>{t("shipping")}</th>
              <td data-title="Subtotal">
                <span className="woocommerce-Price-amount amount">
                  {calculate.delivery_fee != "0" && (
                    <bdi>
                      <span className="woocommerce-Price-currencySymbol">
                        {t("aed")}
                      </span>
                      &nbsp;{calculate.delivery_fee}
                    </bdi>
                  )}

                  {calculate.delivery_fee == "0" && (
                    <div className="bold" style={{ color: "green" }}>
                      {t('free')}
                    </div>
                  )}
                </span>
              </td>
            </tr>

            <tr className="order-total">
              <th>{t('total')}</th>
              <td>
                <strong>
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      <span className="woocommerce-Price-currencySymbol">
                        {t('aed')}
                      </span>
                      &nbsp;{calculate.total}
                    </bdi>
                  </span>
                </strong>{" "}
              </td>
            </tr>
          </tfoot>
        </table>
        <input type="hidden" name="lang" defaultValue="en" />
        <div
          id="payment"
          className="woocommerce-checkout-payment"
          style={{ position: "static", zoom: 1 }}
        >
          <ul className="wc_payment_methods payment_methods methods">
            <li className="wc_payment_method payment_method_cod">
              <input
                id="payment_method_cod"
                type="radio"
                className="input-radio"
                name="payment_method"
                defaultValue="cod"
                checked={paymentMethod == "cod"}
                data-order_button_text=""
                onChange={() => {
                  setPaymentMethod("cod");
                }}
              />
              <label htmlFor="payment_method_cod">{t('Cash on delivery')}</label>
              <div className="payment_box payment_method_cod">
                <p>Pay with cash upon delivery.</p>
              </div>
            </li>
            <li className="wc_payment_method payment_method_ngenius">
              <input
                id="payment_method_ngenius"
                type="radio"
                className="input-radio"
                name="payment_method"
                defaultValue="ngenius"
                data-order_button_text=""
                checked={paymentMethod == "bank"}
                onChange={() => {
                  setPaymentMethod("bank");
                }}
              />
              <label htmlFor="payment_method_ngenius">
                {t('Debit/Credit Card Payment')}{" "}
              </label>
              <div
                className="payment_box payment_method_ngenius"
                style={{ display: "none" }}
              >
                <p>You will be redirected to our secure payment gateway.</p>
              </div>
            </li>
            {true && (
              <li className="wc_payment_method payment_method_tabby_installments">
                <input
                  id="payment_method_tabby_installments"
                  type="radio"
                  className="input-radio"
                  name="payment_method"
                  defaultValue="tabby_installments"
                  data-order_button_text=""
                  checked={paymentMethod == "tabby"}
                  onChange={() => {
                    setPaymentMethod("tabby");
                  }}
                />

                <label htmlFor="payment_method_tabby_installments" style={{ display: 'flex' }}>
                  {/* <TabbyCheckoutComponent
                  amount={calculate.total}
                  language={"en"}
                /> */}
                  Pay in 4. No interest, no fees. / قسّمها على 4. بدون أي فوائد، أو رسوم
                  <img
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                      marginLeft: 10,
                      marginRight: 10
                    }}
                    className="info"
                    data-tabby-info="installments"
                    data-tabby-price={calculate.total}
                    data-tabby-currency="AED"
                    data-tabby-language="en"
                    data-tabby-installments-count={4}
                    src="https://hiphonetelecom.com/wp-content/plugins/tabby-checkout/images/info.png"
                    alt="Tabby"
                  />
                  <img
                    className="ms-2"
                    src="https://hiphonetelecom.com/wp-content/plugins/tabby-checkout/images/logo_green.png"
                    alt="4 interest-free payments"
                  />{" "}
                </label>
              </li>
            )}
          </ul>
          <div className="form-row place-order">
            <noscript>
              Since your browser does not support JavaScript, or it is disabled,
              please ensure you click the &lt;em&gt;Update Totals&lt;/em&gt;
              button before placing your order. You may be charged more than the
              amount stated above if you fail to do so. &lt;br/&gt;&lt;button
              type="submit" class="button alt"
              name="woocommerce_checkout_update_totals" value="Update
              totals"&gt;Update totals&lt;/button&gt;
            </noscript>
            <div className="woocommerce-terms-and-conditions-wrapper">
              <div className="woocommerce-privacy-policy-text" />
              {<div
                className="woocommerce-terms-and-conditions"
                style={{
                  maxHeight: 200,
                  overflow: "auto",
                }}
              >
                <p>
                  1) To benefit from warranty services, an original invoice is
                  required to verify the serial number and validity of the
                  warranty, or a true copy of it.
                </p>
                <p>2) Warranty covers poor manufacturing, not misuse.</p>
                <p>
                  3) The terms and conditions for extending the warranty are
                  covered by the same conditions of the manufacturer’s warranty,
                  whether for a year or two, depending on the coverage that has
                  been purchased.
                </p>
                <p>
                  4) Products that do not contain a tariff number or a symbol
                  such as UPC number, SN # or IMEI are not covered by the
                  long-term one-two-year warranty.
                </p>
                <p>
                  5) The guarantee applies within the borders of the United Arab
                  Emirates only, unless otherwise stated.
                </p>
                <p>
                  6) Accessories, batteries, consumables and changes in the
                  colour of the phone cover are not covered by the warranty.
                </p>
                <p>
                  7) The main responsibilities of the warranty service remain
                  with the brand owner / main supplier, and the site is the
                  middleman only.
                </p>
                <p>
                  8) Product warranty is applied according to the manufacturer’s
                  conditions, starting from the date of purchase recorded on the
                  sales invoice.
                </p>
                <p>
                  9) The warranty repair period is fourteen (14) days to
                  twenty-one (21) working days for the products covered by the
                  warranty, from the date the product is received from the
                  customer until it is dispatched.
                </p>
                <p>
                  10) The product must be returned in the original packaging,
                  with accessories and instruction booklet, and in a condition
                  that can be sold again. Opened, sealed, damaged or partially
                  used products will not be returned.
                </p>
                <p>
                  11) The company’s warranty policy is to protect your product
                  from any defects in materials, design or workmanship that may
                  occur after purchase, with the exception of Apple products
                  (accessories warranty: one year). Other accessories are
                  subject to a six (6) month warranty starting from the date the
                  product was purchased.
                </p>
                <p>
                  12) Not all products are covered by the warranty. Always check
                  the product to see if they are covered by the warranty.
                </p>
                <p>
                  13) The customer is given a purchase voucher in the amount of
                  the value of the product or the maintenance service, and it is
                  to be used on the website within three months from the date of
                  the voucher or to return the amount value of the product
                  according to the customer’s desire.
                </p>
                <p>
                  14) If the product cannot be repaired but is still under the
                  manufacturer’s warranty, we will provide you with a
                  replacement product (from the same seller), and if a
                  replacement is not available, your money will be fully
                  refunded.
                </p>
                <p>
                  15) In the event that the product is returned or repaired, the
                  site is not responsible for transferring data to another
                  device or losing information that was on the device at the
                  time of repairing or retrieval.
                </p>
                <p>16) Mobile phones have no return and exchange option</p>
              </div>}
              <p style={{ textAlign: 'start' }} className="form-row validate-required ms-3 me-3 mt-3">
                <label className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">
                  <input
                    type="checkbox"
                    className="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox"
                    name="terms"
                    id="terms"
                    checked={terms_and_conditions}
                    onChange={(e) => {
                      setTermsAndConditions(!terms_and_conditions);
                    }}
                  />
                  <span className="me-4">
                    {t('I have read and agree to the website')}{" "}
                    <a
                      href="https://hiphonetelecom.com/terms-and-conditions/"
                      className="woocommerce-terms-and-conditions-link"
                      target="_blank"
                    >
                      {t('terms and conditions')}
                    </a>
                  </span>
                  &nbsp;<span className="required">*</span>
                </label>
                <input type="hidden" name="terms-field" defaultValue={1} />
              </p>
            </div>
            <div
              className="button alt"
              id="place_order"
              style={{ textAlign: "center" }}
              onClick={() => {
                checkout();
              }}
            >
              {t("place_order")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
