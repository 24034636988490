import { ActionType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { CheckoutActions } from './actions';import { AppActions } from '../../../modules/app/actions';
import { AddressesApis } from '../../../server/api/Addresses';
import { CartApis } from '../../../server/api/Cart';
import { CheckoutApis } from '../../../server/api/Checkout';
import { CheckoutPayload } from '../../../server/payload/checkout';
import { RootState } from '../../../store/rootState';
import { AuthApis } from '../../../server/api/Auth';
import { Api } from '../../../server/api';
import { OrdersApis } from '../../../server/api/Orders';
import { OffersApis } from '../../../server/api/Offers';


function* checkout({ payload }: ActionType<typeof CheckoutActions.checkout.request>): SagaIterator {
  try {
    console.log('errors length');
    const { errors, billingAddress, shippingAddress, paymentMethod, selectedOffer } = yield* select((state: RootState) => state.checkout) as any;
    console.log('errors length');
    console.log((errors ?? []).length);
    if(errors.length > 0) {
      return;
    }

    console.log('payload')
    console.log(payload)

    const { login, user, cart } = yield* select((state: RootState) => state.app) as any;
    const { coupon } = yield* select((state: RootState) => state.cart) as any;
    if (!login || !user) {
      if(payload) {
        console.log('login payload')
        console.log(payload)
        const { data } = yield* call(AuthApis.login, payload) as any;
        console.log('login upon checkout')
        console.log(data.data)
        yield* put(AppActions.setUser(data.data)) as any;
        //localStorage.setItem('token', data.data.token);
        console.log('token: ');
        console.log(data.data.token);
        // Set token axios
        Api.setAuthToken(data.data.token)
        // Indicate as LoggedIn
        yield* put(AppActions.login()) as any; 
      }
    }

    // Insure token is set
    yield* put(AppActions.insureToken()) as any;

    // Authorize
    const body : CheckoutPayload = {
      products: cart.map((item) => {
        return { id: item.product.id, quantity: item.quantity };
      }),
      payment_method: paymentMethod,
      order_from: 'web',
      note: shippingAddress.note ?? '',
      coupon: coupon,
      billing_address: {
        first_name: billingAddress.first_name,
        last_name: billingAddress.last_name,
        company: billingAddress.company,
        country: billingAddress.country,
        street: billingAddress.street,
        apartment: billingAddress.apartment,
        city: billingAddress.city,
        state: billingAddress.state,
        phone: billingAddress.phone,
        email: billingAddress.email
      },
      shipping_address: {
        first_name: shippingAddress.first_name,
        last_name: shippingAddress.last_name,
        company: shippingAddress.company,
        country: shippingAddress.country,
        street: shippingAddress.street,
        apartment: shippingAddress.apartment,
        city: shippingAddress.city,
        state: shippingAddress.state,
        phone: shippingAddress.phone,
        email: shippingAddress.email
      },
      offer: selectedOffer ? selectedOffer.id : undefined
    }
    console.log('checkout payload')
    console.log(body);
    const { data } = yield* call(CheckoutApis.checkout, body) as any;
    console.log('checkout response');
    console.log(data);

    // Create Payment Link
    if(paymentMethod == 'bank') {
      const paymentLinkResponse = yield* call(CheckoutApis.paymentLink, data.data) as any;
      console.log('payment link response');
      console.log(paymentLinkResponse.data.data);

      // Checkout succeeded
      yield* put(CheckoutActions.checkout.success(paymentLinkResponse.data.data)) as any;
    } 
    else {
      yield* put(CheckoutActions.checkout.success(undefined)) as any;
    }
    
    // Empty cart
    yield* put(AppActions.cart.clear()) as any;
    yield* put(CheckoutActions.removeSelectedOffer) as any;
  } catch (error) {
    console.log('checkout error');
    console.log(error);
    if(payload) {
      yield* put(CheckoutActions.checkout.fail('Could not login, Please check you credentials')) as any;
    } else {
      const errorMessage = (error as any).response.data.message;
      yield* put(CheckoutActions.checkout.fail(errorMessage ?? 'Unable to place your order, Please try again later.')) as any;
    }
  }
}

function* addresses(): SagaIterator {
  try {
    console.log('addresses saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(AddressesApis.addresses) as any;
    console.log('addresses response: ');
    console.log(data.data);
    yield* put(CheckoutActions.addresses.success(data.data)) as any;
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(CheckoutActions.addresses.fail(errorMessage)) as any;
  }
}

function* cart({ }: ActionType<typeof CheckoutActions.cart.request>): SagaIterator {
  try {
    const { login, user } = yield* select((state: RootState) => state.app) as any;
    if (!login || !user) {
      return;
    }

    // Fetching wishlistå products
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CartApis.cart) as any;
    console.log('cart response: ');
    console.log(data);
    yield* put(CheckoutActions.cart.success({
      products: data.data
    })) as any;

    var cartList = data.data.map((item) => {
      return { 'quantity': item.quantity, 'product': item }
    });
    yield* put(AppActions.cart.set(cartList)) as any;
  } catch (error) {
    yield* put(CheckoutActions.cart.fail('')) as any;
  }
}

function* calculate({ payload }: ActionType<typeof CheckoutActions.calculate.request>): SagaIterator {
  try {
    // Fetching wishlistå products
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CartApis.calculate, payload) as any;
    console.log('calculate response: ');
    console.log(data);
    yield* put(CheckoutActions.cart.success({
      products: data.data
    })) as any;
    yield* put(CheckoutActions.calculate.success(data.data)) as any;
  } catch (error) {
    yield* put(CheckoutActions.cart.fail('')) as any;
  }
}

function* checkoutStatus({ payload }: ActionType<typeof CheckoutActions.checkoutStatus.request>): SagaIterator {
  try {
    console.log('checkout status payload');
    console.log(payload);

    if(!payload) {
      yield* put(CheckoutActions.checkoutStatus.fail('')) as any;
      return;
    }

    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CheckoutApis.checkoutStatus, payload) as any;
    console.log('checkout status response:');
    console.log(data);
    yield* put(CheckoutActions.checkoutStatus.success(data.data)) as any;
  } catch (error) {
    console.log('checkout status error:');
    const errorResponse = (error as any).response;
    console.log(errorResponse)
    yield* put(CheckoutActions.checkoutStatus.fail('')) as any;
  }
}

function* offers({ }: ActionType<typeof CheckoutActions.offers.request>): SagaIterator {
  try {
    console.log('offers saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OffersApis.offers) as any;
    console.log('offers response: ');
    console.log(data.data);
    yield* put(CheckoutActions.offers.success(data.data)) as any;
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(CheckoutActions.offers.fail(errorMessage)) as any;
  }
}


export function* watchCheckout(): SagaIterator {
  yield* takeLatest(CheckoutActions.checkout.request, checkout) as any;
  yield* takeLatest(CheckoutActions.addresses.request, addresses) as any;
  yield* takeLatest(CheckoutActions.cart.request, cart) as any;
  yield* takeLatest(CheckoutActions.calculate.request, calculate) as any;
  yield* takeLatest(CheckoutActions.checkoutStatus.request, checkoutStatus) as any;
  yield* takeLatest(CheckoutActions.offers.request, offers) as any;
}