import React, { CSSProperties, useEffect, useState, Component } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootState';
import { useAction } from '../../../utils/hooks/action';
import { ProductModel } from '../../../server/models/product';
import { WishlistActions } from '../../wishlist/module/actions';
import { CartActions } from '../../cart/module/actions';
import { ProductsSideMenu } from '../../products/components/products_side_menu';
import { useTranslation } from 'react-i18next';
import { RelatedProduct } from './related_products';
import { UPLOADS_URL } from '../../../configs';
import { Magnifier } from '../../../components/magnify';
import { useLanguage } from '../../../utils/hooks/language';
import TabbyPromoComponent from '../../../components/tabby_promo';

export const ProductDetails = (props: { id: string }) => {
  // States
  const { wishlist, cart } = useSelector((state: RootState) => state.app);
  const { loadingAddToCart } = useSelector((state: RootState) => state.cart);
  const { loadingAddToWishlist } = useSelector((state: RootState) => state.wishlist);
  const { product } = useSelector((state: RootState) => state.product);
  // Actions
  const addToWishlistAction = useAction(WishlistActions.add.request);
  const addToCartAction = useAction(CartActions.add.request);
  const { t } = useTranslation();

  const discount = (product: ProductModel) => {
    return Math.floor((100 - (+product.sale_price / +product.regular_price) * 100));
  }

  const [image, setImage] = useState<string>('');
  const [quantity, setQuantity] = useState(1);
  const [en, ar] = useLanguage();

  useEffect(() => {
    console.log('received product2')
    console.log(product)
    if (product) {
      setImage(product.image)
    }
  }, [product])

  if (!product) {
    return <div>loading</div>
  }

  const changeImage = (event, image) => {
    setImage(image)
  }

  const inWishlist = () => {
    return (wishlist ?? []).filter((item) => item.id == props.id).length > 0
  }

  const inCart = () => {
    return (cart ?? []).filter((item) => item.product.id == props.id).length > 0
  }

  const ProductDetails = () => {
    return (
      <div id="primary" className="w-100">
        <main id="main" className="site-main">
          <div className="woocommerce-notices-wrapper" />
          <div
            id="product-109059"
            className="product type-product post-109059 status-publish first instock product_cat-accessories product_cat-cases-and-covers product_tag-gold product_tag-iphone-13-pro-max product_tag-vip-gold-hiphone-telecom has-post-thumbnail sale purchasable product-type-simple"
          >
            <div className="single-product-wrapper">
              <div className="product-images-wrapper">
                {discount(product) > 0 && <span className="onsale">
                  -<span className="percentage">{discount(product)}%</span>
                </span>}
                <div
                  className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-5 images"
                  data-columns={5}
                  style={{
                    opacity: 1,
                    transition: "opacity 0.25s ease-in-out 0s"
                  }}
                >
                  <a
                    href="#"
                    className="woocommerce-product-gallery__trigger"
                  >
                    {/* <img
                            draggable="false"
                            role="img"
                            className="emoji"
                            alt="🔍"
                            src="https://s.w.org/images/core/emoji/13.1.0/svg/1f50d.svg"
                          /> */}
                  </a>
                  <div
                    className="product-image"
                    style={{
                      overflow: "hidden",
                      // position: "relative",
                      aspectRatio: '1/1',
                      backgroundColor: '#D9D9D9'
                    }}
                  >

                    {/* 
                          */}
                    <Magnifier imgSrc={image} magnifierRadius={80} />
                    {/* <img
                      src={image}
                    /> */}

                  </div>
                  <ol className="flex-control-nav flex-control-thumbs">
                    <li>
                      <img
                        src={product.image}
                        className={`product-image-ts ${product.image == image ? 'flex-active' : ''}`}
                        draggable="false"
                        onClick={(event) => {
                          changeImage(event, product.image)
                        }}
                        style={{ backgroundColor: '#D9D9D9' }}
                      />
                    </li>

                    {(product.images ?? []).map(img => {
                      return (

                        <li>
                          <img
                            src={img.image}
                            className={`product-image-ts ${img.image == image ? 'flex-active' : ''}`}
                            draggable="false"
                            onClick={(event) => {
                              changeImage(event, img.image)
                            }}
                            style={{ backgroundColor: '#D9D9D9' }}
                          />
                        </li>
                      );
                    })}

                  </ol>
                </div>
                <div className="section-onsale-product">
                  <div className="onsale-product"></div>
                </div>
              </div>
              {/* /.product-images-wrapper */}
              <div className="summary entry-summary">
                <span className="loop-product-categories">
                  {(product.categories ?? []).map(catP => {
                    return (

                      <a
                        href={`products?id=${catP.category_id}`}
                        rel="tag"
                      >
                        {en ? catP.name_en : catP.name}
                        {(product.categories ?? []).length > 1 && ","}
                      </a>

                    );
                  })}
                </span>

                <h1 className="product_title entry-title">
                  {product.name_en}
                </h1>

                {/* Brand Image */}
                {product.brand_image && <div className="brand">
                  <a>
                    <img
                      src={UPLOADS_URL + product.brand_image}
                    />
                  </a>
                </div>}
                <hr className="single-product-title-divider" />
                <div className="action-buttons">
                  <div
                    className="yith-wcwl-add-to-wishlist add-to-wishlist-109059  wishlist-fragment on-first-load"
                    data-fragment-ref={109059}
                    data-fragment-options='{"base_url":"","in_default_wishlist":false,"is_single":true,"show_exists":false,"product_id":109059,"parent_product_id":109059,"product_type":"simple","show_view":true,"browse_wishlist_text":"Browse wishlist","already_in_wishslist_text":"The product is already in your wishlist!","product_added_text":"Product added!","heading_icon":"fa-heart-o","available_multi_wishlist":false,"disable_wishlist":false,"show_count":false,"ajax_loading":false,"loop_position":"after_add_to_cart","item":"add_to_wishlist"}'
                  >
                    {/* ADD TO WISHLIST */}
                    {loadingAddToWishlist && <i className="fa fa-spinner fa-spin me-2"></i>}
                    {!loadingAddToWishlist && <>
                      {!inWishlist() && <div
                        onClick={() => {
                          if (product) {
                            addToWishlistAction(product)
                          }
                        }}
                        className="yith-wcwl-add-button">
                        <a
                          rel="nofollow"
                          data-product-id={109059}
                          data-product-type="simple"
                          data-original-product-id={109059}
                          className="add_to_wishlist single_add_to_wishlist"
                          data-title="Add to wishlist"
                        >
                          <span>{t('add_to_wishlist')}</span>
                        </a>
                      </div>}
                      {inWishlist() && <span className="feedback">
                        <i className="yith-wcwl-icon fa fa-heart"></i>		{t('product_added')}!	</span>}
                    </>}
                  </div>
                  <a
                    href="https://hiphonetelecom.com?action=yith-woocompare-add-product&id=109059&lang=en"
                    className="add-to-compare-link"
                    data-product_id={109059}
                  >
                    Compare
                  </a>
                </div>
                {
                  product.headline_en && <div className="woocommerce-product-details__short-description">
                    <p>
                      {product.headline_en}
                    </p>
                  </div>
                }

                {product.is_available == 1 && <p className="price">
                  <span className="electro-price">
                    <ins>
                      <span className="woocommerce-Price-amount amount">
                        <bdi>
                          <span className="woocommerce-Price-currencySymbol">
                            {t('aed')}
                          </span>
                          &nbsp;{(+product.sale_price).toFixed(2)}
                        </bdi>
                      </span>
                    </ins>{" "}
                    {discount(product) > 0 && <del>
                      <span className="woocommerce-Price-amount amount">
                        <bdi>
                          <span className="woocommerce-Price-currencySymbol">
                            {t('aed')}
                          </span>
                          &nbsp;{(+product.regular_price).toFixed(2)}
                        </bdi>
                      </span>
                    </del>}
                  </span>
                </p>}
                {product.is_available == 1 && <div className='mb-3'>
                  <TabbyPromoComponent amount={product.sale_price} language={'en'} />
                </div>}
                {(product.is_available != 1) && <div className='font16' style={{ color: 'red' }}>{t('product_is_not_available')}</div>}
                {(product.is_available == 1) && <div className='d-flex'>
                  {!loadingAddToCart && <div className="quantity me-2">
                    <label htmlFor="quantity_6467ab6c0f185">{t('quantity')}</label>
                    <input
                      type="number"
                      id="quantity_6467ab6c0f185"
                      className="input-text qty text"
                      step={1}
                      min={1}
                      max=""
                      name="quantity"
                      value={quantity}
                      title="Qty"
                      size={4}
                      onChange={(event) => {
                        setQuantity(+event.target.value);
                      }}
                      inputMode="numeric"
                      aria-labelledby="Hiphone Luxury Iphone 13 Pro Max 24K Butterfly Artistic Edition quantity"
                    />
                  </div>}
                  {!loadingAddToCart && <button
                    className="single_add_to_cart_button button alt"
                    onClick={() => {
                      addToCartAction({ quantity: quantity, product: product })
                    }}
                  >
                    {inCart() ? t('update_cart') : t('add_to_cart')}
                  </button>}
                  {loadingAddToCart && <div className="loader"></div>}
                </div>}
              </div>
            </div>
            {/* /.single-product-wrapper */}
            <div className="woocommerce-tabs wc-tabs-wrapper">
              <ul className="tabs wc-tabs" role="tablist">
                <li
                  className="description_tab active"
                  id="tab-title-description"
                  role="tab"
                  aria-controls="tab-description"
                >
                  <a href="#tab-description">{t('description')}</a>
                </li>
                <li
                  className="specification_tab"
                  id="tab-title-specification"
                  role="tab"
                  aria-controls="tab-specification"
                >
                  <a href="#tab-specification">{t('specification')}</a>
                </li>
              </ul>
              <div
                className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab"
                id="tab-description"
                role="tabpanel"
                aria-labelledby="tab-title-description"
                style={{ display: "block" }}
              >
                <div className="electro-description clearfix">
                  <p>
                    {product.description_en}
                  </p>
                </div>

              </div>
              <div
                className="woocommerce-Tabs-panel woocommerce-Tabs-panel--specification panel entry-content wc-tab"
                id="tab-specification"
                role="tabpanel"
                aria-labelledby="tab-title-specification"
                style={{ display: "none" }}
              >
                <table className="woocommerce-product-attributes shop_attributes">
                  <tbody>
                    {(product.attributes ?? []).map(attr => {
                      return (

                        <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_size">
                          <th className="woocommerce-product-attributes-item__label">
                            {attr.attr_name_en}
                          </th>
                          <td className="woocommerce-product-attributes-item__value">
                            <p>
                              <a
                                href="#"
                                rel="tag"
                              >
                                {attr.value_name_en}
                              </a>
                            </p>
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table>
              </div>
            </div>

            {/* Related Products */}
            <section className="related products">
              <h2>{t('related_products')}</h2>
              <ul
                data-view="grid"
                data-toggle="regular-products"
                className="products columns-4 columns__wide--4"
              >
                {
                  // .filter((item, index) => item.sale_price !== '0')
                  [...(product.similar ?? []).filter((item, index) => index < 4).map((product, index) =>
                    <RelatedProduct product={product} />
                  )]
                }
              </ul>
            </section>
          </div>
        </main>
        {/* #main */}
      </div>
    )
  }

  return (
    <div id="content" className="site-content" tabIndex={-1}>
      <div className="container">
        <nav className="woocommerce-breadcrumb d-flex w-100">
          <a href="#">{t('home')}</a>
          <span className="delimiter">
            <i className="fa fa-angle-right" />
          </span>

          {(product.categories ?? []).length > 0 && <div>
            <a href={`products?id=${product.categories[0].category_id}`}>
              {en ? product.categories[0].name_en : product.categories[0].name}
            </a>
            <span className="delimiter">
              <i className="fa fa-angle-right" />
            </span>
          </div>
          }

          <div>{product?.name_en}</div>
        </nav>

        <div>
          <div className='row'>
            <div className='col-12 col-lg-3 d-none d-lg-block'>
              <ProductsSideMenu showAttributes={false} />
            </div>
            <div className='col-12 col-lg-9'>
              <ProductDetails />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}