import { ProductModel } from "../../../server/models/product";
import HeartSvgImage from '../../../assets/heart.svg'
import IphoneProductAdImage from '../../../assets/iphone14-product-ad.png'
import HeartRedSvgImage from '../../../assets/heart-red.svg'
import { useNavigate } from "react-router";
import { WishlistActions } from "../../wishlist/module/actions";
import { useAction } from "../../../utils/hooks/action";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const BestSell = (props: { product: ProductModel }) => {
    // Navigation
    const navigate = useNavigate();
    // Actions
    const addToWishlistRequestAction = useAction(WishlistActions.add.request);
    const removeFromWishlistRequestAction = useAction(WishlistActions.remove.request);
    // States
    const { wishlist } = useSelector((state: RootState) => state.app);
    const { loadingAddToWishlist, loadingRemoveFromWishlist } = useSelector((state: RootState) => state.wishlist);
    const [loading, setLoading] = useState(false);
    // Translation
    const { t, i18n } = useTranslation();

    const discount = (product: ProductModel) => {
        return Math.floor((100 - (+product.sale_price / +product.regular_price) * 100));
    }

    useEffect(() => {
        if (!loadingAddToWishlist && !loadingRemoveFromWishlist) {
            setLoading(false);
        }
    }, [loadingAddToWishlist, loadingRemoveFromWishlist]);

    const inWishlist = () => {
        return (wishlist ?? []).filter((item) => item.id == props.product.id).length > 0
    }

    return (
        <li key={`best-sell-${props.product.id}`}>
            <div className="pointer" onClick={() => {
                navigate(`/product/${props.product.slug}`);
            }}
                style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <img style={{ width: '100%', height: '100%', background: 'white' }} className='mb-2' src={props.product.image} />
                <div className='mb-2' style={{ display: 'block', fontWeight: 400, fontSize: 16, width: '100%', color: '#343E48', textAlign: 'start', maxHeight: '3.6em', lineHeight: '1.2em', whiteSpace: 'pre-wrap', textOverflow: 'ellipsis' }}>{props.product.name_en}</div>
                <div className="d-flex justify-content-between algn-items-center mt-2 w-100">
                    {/* <span className="onsale" style={{ borderRadius: 6 }}>
                    -<span className="percentage">{10}%</span>
                  </span> */}
                    { discount(props.product) > 0 && <div className='d-flex justify-content-center align-items-center ps-2 pe-2' style={{ backgroundColor: '#EB212E', height: 25, borderRadius: 6, fontWeight: 'bold', fontSize: 13, color: 'white' }}><div >{discount(props.product)}%</div></div>}
                    { discount(props.product) == 0 && <div className='bold' style={{ fontSize: 14, color: 'red', fontWeight: 'bold' }}>
                        {t('aed')} {(+props.product.sale_price).toFixed(2)}
                    </div>}
                    { <img onClick={(e) => {
                        if (inWishlist()) {
                            setLoading(true);
                            removeFromWishlistRequestAction(props.product);
                        }
                        else {
                            setLoading(true);
                            addToWishlistRequestAction(props.product);
                        }
                        e.stopPropagation();
                    }} className="pointer" width={28} height={28} src={inWishlist() ? HeartRedSvgImage : HeartSvgImage} />}
                    {/* {loading && <div style={{ width: 20, height: 20 }} className="loader"></div>} */}
                </div>

                { (discount(props.product) > 0) && <div className="d-flex justify-content-start align-items-end w-100" style={{ fontWeight: 400, textAlign: 'start', fontSize: 12, color: 'red', height: '100%' }}>
                    <div className='bold' style={{ fontSize: 14 }}>
                        {t('aed')} {(+props.product.sale_price).toFixed(2)}
                    </div>
                    <div className='ms-2 me-2' />
                    <del style={{ fontSize: 13, color: 'black' }}>
                        <div style={{ color: 'black' }}>{t('aed')} {(+props.product.regular_price).toFixed(2)}</div>
                    </del>
                </div>}
            </div>
        </li>
    );
}