import { useTranslation } from "react-i18next";
import { useLanguage } from "../../utils/hooks/language";
import { SearchOption } from "./search_option"
import { useNavigate, createSearchParams } from 'react-router-dom';

export var SearchOptions = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='d-none d-lg-block' style={{ background: '#ED1C24' }}>
            <div className='container' style={{
                display: 'flex',
                flexWrap: 'wrap'
            }}>
                <SearchOption
                    title={`${t('aed_10_30_items')}`}
                    onClick={() => { 
                        navigate({
                            pathname: `/products/aed_10_30_items`, search: `?${createSearchParams({
                                'min_price': '0',
                                'max_price': '10'
                            })}`
                        })
                    }} />
                <SearchOption title={`${t('sale')}`} onClick={() => { 
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'min_price': '1'
                        })}`
                    })
                }} />
                <SearchOption title={`${t('audio_and_bluetooth')}`} onClick={() => { 
                    navigate({
                        pathname: `/products/audio_and_bluetooth`, search: `?${createSearchParams({
                            'id': '25'
                        })}`
                    })
                }} />
                <SearchOption title={`${t('battery')}`} onClick={() => { 
                    navigate({
                        pathname: `/products/battery`, search: `?${createSearchParams({
                            'id': '35'
                        })}`
                    })
                }} />
                <SearchOption title={`${t('bundle_offer')}`} onClick={() => { 
                    navigate({
                        pathname: `/products/bundle_offer`, search: `?${createSearchParams({
                            'id': '37'
                        })}`
                    })
                }} />
                <SearchOption title={`${t('cases_and_covers')}`} onClick={() => { 
                    navigate({
                        pathname: `/products/cases_and_covers`, search: `?${createSearchParams({
                            'id': '28'
                        })}`
                    })
                }} />
                <SearchOption title={`${t('crazy_deals')}`} onClick={() => { 
                    navigate({
                        pathname: `/products/crazy_deals`, search: `?${createSearchParams({
                            'id': '42'
                        })}`
                    })
                }} />
                <SearchOption title={`${t('powerbank')}`} onClick={() => { 
                    navigate({
                        pathname: `/products/powerbank`, search: `?${createSearchParams({
                            'id': '46'
                        })}`
                    })
                }} />
                <SearchOption title={`${t('screen_and_camera_protection')}`} onClick={() => { 
                    navigate({
                        pathname: `/products/screen_and_camera_protection`, search: `?${createSearchParams({
                            'id': '31'
                        })}`
                    })
                }} />
                <SearchOption title={`${t('repair')}`} onClick={() => { 
                    navigate({
                        pathname: `/repair`
                    })
                }} />
            </div>
        </div>
    )
}