import React, { CSSProperties, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootState';
import { useAction } from '../../utils/hooks/action';
import { ProductActions } from './module/actions';
import { Footer } from '../../components/footer'
import { ProductDetails } from './components/product_details'
import { useParams } from 'react-router';
import { Header } from '../../components/header/headers';
import { BasePage } from '../base/base';


export const ProductPage = () => {
  // Url params
  let { slug } = useParams();
  // States
  const { wishlist, cart } = useSelector((state: RootState) => state.app);
  const { loading, product } = useSelector((state: RootState) => state.product);
  // Actions
  const productsAction = useAction(ProductActions.product.request);

  useEffect(() => {
    console.log(`search params id: ${slug}`)
    if (slug) {
      productsAction({ slug: slug })
    }
  }, [slug])

  useEffect(() => {
    console.log('received product')
    console.log(product)
  }, [product])

  if (loading) {
    return <></>
  }

  if (!product || !slug) {
    return <div>Product not available</div>
  }

  return (
    <BasePage>
      <div className="off-canvas-wrapper">
        <div id="page" className="hfeed site">
          <Header />
          <div id="content" className="site-content" >
            <ProductDetails id={product.id!} />
            <Footer />
          </div>
        </div>
      </div>
    </BasePage>
  )
}