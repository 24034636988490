import { ProductModel } from "../../../server/models/product";
import HeartSvgImage from '../../../assets/heart.svg'
import HeartRedSvgImage from '../../../assets/heart-red.svg'
import { WishlistActions } from "../../wishlist/module/actions";
import { useAction } from "../../../utils/hooks/action";
import { useEffect, useState } from "react";
import { RootState } from "../../../store/rootState";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export const FlashSale = (props: { product: ProductModel }) => {
  // Navigation
  const navigate = useNavigate();
  // Actions
  const addToWishlistRequestAction = useAction(WishlistActions.add.request);
  const removeFromWishlistRequestAction = useAction(WishlistActions.remove.request);
  // States
  const { wishlist } = useSelector((state: RootState) => state.app);
  const { loadingAddToWishlist, loadingRemoveFromWishlist } = useSelector((state: RootState) => state.wishlist);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (!loadingAddToWishlist && !loadingRemoveFromWishlist) {
      setLoading(false);
    }
  }, [loadingAddToWishlist, loadingRemoveFromWishlist]);

  const discount = (product: ProductModel) => {
    return Math.floor((100 - (+product.sale_price / +product.regular_price) * 100));
  }

  const inWishlist = () => {
    return (wishlist ?? []).filter((item) => item.id == props.product.id).length > 0
  }

  return (
    <li key={`flash-sell-${props.product.id}`}>
      <div onClick={() => {
        navigate(`/product/${props.product.slug}`);
      }} className='ps-3 pe-3 pt-2 item-shadow pointer' style={{ border: '3px solid #ED1C24', borderRadius: 25, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <img style={{ width: '60%', height: '60%', borderRadius: 20 }} className='mb-2 mt-2' src={props.product.image} />
        <div style={{ display: 'block', fontWeight: 400, fontSize: 15, width: '100%', color: '#343E48', textAlign: 'start', maxHeight: '3.6em', lineHeight: '1.2em', whiteSpace: 'pre-wrap', textOverflow: 'ellipsis' }}>{props.product.name_en}</div>
        <div className="d-flex justify-content-between algn-items-center mt-2 w-100">
          <span className="onsale" style={{ borderRadius: 6, margin: 0 }}>
            -<span className="percentage">{discount(props.product)}%</span>
          </span>
          {<img onClick={(e) => {
            if (inWishlist()) {
              setLoading(true);
              removeFromWishlistRequestAction(props.product);
            } 
            else {
              setLoading(true);
              addToWishlistRequestAction(props.product);
            }
            e.stopPropagation();
          }} className="pointer" width={25} height={25} src={inWishlist() ? HeartRedSvgImage : HeartSvgImage} />}
          {/* { loading && <div style={{width:20, height: 20}} className="loader"></div>} */}
        </div>
        <div className="d-flex justify-content-start align-items-center w-100" style={{ fontWeight: 400, textAlign: 'start', }}>
          <div style={{ fontSize: 12 }}>
            AED &nbsp;{(+props.product.sale_price).toFixed(2)}
          </div>
          <div style={{ width: 5 }} />
          <del style={{ fontSize: 11, color: 'red' }}>
            AED &nbsp;{(+props.product.regular_price).toFixed(2)}
          </del>
        </div>
      </div>
    </li>
  );
}