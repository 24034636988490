import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { SliderModel } from "../../../server/models/slider";
import { BASE_URL, UPLOADS_URL } from "../../../configs";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { BrandModel } from "../../../server/models/brand";
import { useSearchParams } from "react-router-dom";


export function ProductsBrands() {
  // Search params
  let [searchParams, setSearchParams] = useSearchParams();
  const { loadingBrands, brands } = useSelector((state: RootState) => state.home);
  const [index, setIndex] = useState<number | undefined>(undefined);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    var attributes: any = searchParams.get("attrs");

    if (attributes) { 
      console.log(attributes)
      attributes.split(',').forEach((item) => {
        brands.forEach((brand, index) => {
          if(brand.brand_name_en.toLocaleLowerCase() == item.toLocaleLowerCase()) {
            setIndex(index)
          }
        }) 
      })
    }
    
  }, [searchParams])

  if(searchParams.get("id") === '20') {
    return (
      <div className="container mb-3"><img width="2560" height="776p" src={"https://hiphonetelecom.com/v2/uploads/676578fd26a152024_12_20.jpeg"} className="attachment-full" alt="" loading="lazy" sizes="(max-width: 1170px) 100vw, 1170px" /></div>
    );
  }

  if(brands.length == 0) { 
    return <></>
  }
  
  if(index == undefined) {
    return <></>
  }

  return (
    <div className="container mb-3"><img width="2560" height="776p" src={UPLOADS_URL + brands[index].banner_image} className="attachment-full" alt="" loading="lazy" sizes="(max-width: 1170px) 100vw, 1170px" /></div>
  );
}

