import { CategoryModel } from "../../../server/models/category";
import { useNavigate, useSearchParams, createSearchParams, useLocation, URLSearchParamsInit } from 'react-router-dom';
import { useLanguage } from "../../../utils/hooks/language";

export const ProductsSideMenuCategory = (props: { category: CategoryModel }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [en, ar] = useLanguage();
    const location = useLocation();

    console.log(props.category.sub_categories)

    const navigateToProducts = (id: string | undefined, title: string | undefined) => {
        if (location.pathname.includes('/product/')) {
            navigate({
                pathname: `/products/${title?.toString().trim().toLowerCase().replaceAll("_", "-").replaceAll(" ", "-")}}`, search: `?${createSearchParams({
                    'id': `${id?.toString()}`,
                    'title': `${title?.toString()}`,
                })}`
            });
        }
        else {
            var params = {}
            params["page"] = "0"

            if (title) {
                params["title"] = title
            }
            else {
                var _title = searchParams.get('title')
                if (_title) {
                    params["title"] = _title
                }
            }

            if (id) {
                delete params["name"]
                delete params["attrs"]
                params["id"] = id
                searchParams.delete('name')
                searchParams.delete('attrs')
                searchParams.set("id", id);
            }
            else {
                var _id = searchParams.get('id')
                if (_id) {
                    params["id"] = _id
                    searchParams.set("id", _id);
                }
            }

            // setSearchParams(searchParams, { replace: true });
            navigate({
                pathname: `/products/${title?.toString().trim().toLowerCase().replaceAll("_", "-").replaceAll(" ", "-")}`, search: `?${createSearchParams(params)}`
            });
        }
    }

    const SubCategory = (props: { category: CategoryModel }) => {
        return (
            <div className="ps-5 pt-1 pb-1" style={{ backgroundColor: searchParams.get('id') == props.category.id ? '#F8B2B6' : 'transparent' }} onClick={() => {
                navigateToProducts(props.category.id, props.category.name_en);
            }}>
                <span className="no-child" />
                {en ? props.category.name_en : props.category.name}
            </div>
        )
    }

    return (
        <div>
            <div style={{ backgroundColor: searchParams.get('id') == props.category.id ? '#F8B2B6' : 'transparent' }} onClick={() => {
                navigateToProducts(props.category.id, props.category.name_en);
            }} className="pointer">
                <div className="products-side-menu-category-item">
                    <div className={`fa fa-angle-${en ? 'right' : 'left'} ms-2 me-2`} style={{ visibility: ((props.category.sub_categories ?? []).length > 0) ? 'visible' : 'hidden' }} />
                    <div className="bold underline">{en ? props.category.name_en : props.category.name}</div>
                </div>
            </div>
            <div className="children" style={{ display: ((props.category.sub_categories ?? []).length > 0) ? 'inline' : "none" }}>
                <div className="cat-item cat-item-3914">
                    {[...(props.category.sub_categories ?? []).map((subcategory, index) =>
                        <SubCategory category={subcategory} />
                    )]
                    }
                </div>
            </div>
        </div>
    )
}