import { useSelector } from "react-redux";
import { CategoryModel } from "../../../server/models/category"
import { RootState } from "../../../store/rootState";
import { AttributeModel } from "../../../server/models/attribute";
import { ProductsPayload } from "../../../server/payload/products";
import { hexEncode } from "../../../utils/string";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { ProductsSideMenuCategory } from "./products_side_menu_category";
import { Attribute } from "./product_side_menu_attribute";

export const ProductsSideMenu = (props: {showAttributes: boolean}) => {
    const { categories } = useSelector((state: RootState) => state.app);
    const { attributes } = useSelector((state: RootState) => state.products);
    const { t } = useTranslation();
    const location = useLocation();

    const Categories = () => {
        return (
            <div>
                <div className="d-flex align-items-center ps-3 pe-3 bold" style={{backgroundColor:'red', height: 47, color: 'white'}}><div>{t('browse_categories')}</div></div>
                <div>
                    {[...(categories ?? []).map((category, index) =>
                        <ProductsSideMenuCategory category={category} />
                    )]
                    }
                </div>
            </div>
        )
    }

    const Attributes = () => {
        console.log('attrs');
        console.log(attributes);
        return (
            <aside
            >
                {[...Object.keys(attributes).map((key, index) =>
                            <Attribute name={key} attributes={attributes[key] ?? []} />
                        )]}
            </aside>
        )
    }

    return (
        <div className="w-100 pb-1 disable-selection" style={{ textAlign: 'start', backgroundColor: '#F3F3F3'}}>
            <Categories />
            <div className="m-3" style={{backgroundColor: '#EB212E', height: 0.5}}/>
            {props.showAttributes && <Attributes />}
        </div>
    )
}