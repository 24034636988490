import { Topbar } from '../../components/topbar'
import { SearchMenu } from '../../components/header/search_menu'
import { Footer } from '../../components/footer'
import WhatsappImageGreen from "../../assets/footer-whatsapp-green.svg";
import { AboutComponent } from '../../components/about'
import { useTranslation } from 'react-i18next'
import { BasePage } from '../base/base'
import { Header } from '../../components/header/headers'

export const ContactUsPage = () => {
  const { t } = useTranslation();

  return <>
    <BasePage>
      <div>
        <Header />
        <div className='mt-3' />
        <div id="content" className="site-content" tabIndex={-1}>
          <div className="container">
            <nav className="woocommerce-breadcrumb">
              <a href="https://hiphonetelecom.com">{t("contact us")}</a>
              <span className="delimiter">
                <i className="fa fa-angle-right" />
              </span>
              {t("contact us")}
            </nav>
            <div className="site-content-inner">
              <div id="primary" className="w-100">
                <main id="main" className="site-main">
                  <article
                    id="post-65713"
                    className="post-65713 page type-page status-publish hentry"
                  >
                    <header className="entry-header">
                      <h1 className="entry-title">{t("contact us")}</h1>
                    </header>
                    <p className="container">
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center ">
                            {t("mobile_and_whatsapp")}:{" "}
                            <div style={{ direction: "ltr" }}>+971 5519 07002</div>
                          </div>
                          <a href="https://wa.me/+971551907002">
                            <img
                              style={{ height: 20 }}
                              className="me-2 ms-2"
                              src={WhatsappImageGreen}
                            />
                          </a>
                        </div>

                        <div className="d-flex align-items-center me-2 ms-2">
                          {t("landline")}:{" "}
                          <div style={{ direction: "ltr" }}>+971 4 294 6664</div>
                        </div>
                      </div>
                    </p>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </BasePage>
  </>
}