import { useTranslation } from "react-i18next";
import { ProductModel } from "../../../server/models/product";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { useAction } from "../../../utils/hooks/action";
import { WishlistActions } from "../../wishlist/module/actions";
import { CartActions } from "../../cart/module/actions";

export const RelatedProduct = (props: { product: ProductModel }) => {
    // Navigation
    const navigate = useNavigate();
    // Actions
    const addToWishlistRequestAction = useAction(WishlistActions.add.request);
    const removeFromWishlistRequestAction = useAction(WishlistActions.remove.request);
    const addToCartRequestAction = useAction(CartActions.add.request);
    const removeFromCartRequestAction = useAction(CartActions.remove.request);
    // States
    const { wishlist, cart } = useSelector((state: RootState) => state.app);
    const { loadingAddToWishlist, loadingRemoveFromWishlist } = useSelector((state: RootState) => state.wishlist);
    const { loadingAddToCart, loadingRemoveFromCart } = useSelector((state: RootState) => state.cart);
    const [_loadingWishlistOperation, _setLoadingWishlistOperation] = useState(false);
    const [_loadingCartOperation, _setLoadingCartOperation] = useState(false);
    
    // Translation
    const { t, i18n } = useTranslation();

    const discount = (product: ProductModel) => {
        return Math.floor((100 - (+product.sale_price / +product.regular_price) * 100));
    }

    useEffect(() => {
        if (!loadingAddToWishlist && !loadingRemoveFromWishlist) {
            _setLoadingWishlistOperation(false);
        }
    }, [loadingAddToWishlist, loadingRemoveFromWishlist]);

    useEffect(() => {
        if (!loadingAddToCart && !loadingRemoveFromCart) {
            _setLoadingCartOperation(false);
        }
    }, [loadingAddToCart, loadingRemoveFromCart]);

    const inWishlist = () => {
        return (wishlist ?? []).filter((item) => item.id == props.product.id).length > 0
    }

    const inCart = () => {
        return (cart ?? []).filter((item) => item.product.id == props.product.id).length > 0
    }

    return <>
        <li onClick={() => {
            navigate(`/product/${props.product?.slug}`)
        }} className="product type-product post-99099 status-publish instock product_cat-accessories product_cat-cases-and-covers product_cat-isafe-and-maestro product_tag-iphone-xr product_tag-isafe product_tag-rose-gold has-post-thumbnail sale purchasable product-type-variable has-default-attributes">
            <div className="product-outer product-item__outer">
                <div className="product-inner product-item__inner">
                    <div className="product-loop-header product-item__header">
                        <a
                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                        >
                            <h2 className="woocommerce-loop-product__title">
                                {props.product.name_en}
                            </h2>
                            <div className="product-thumbnail product-item__thumbnail">
                                <img
                                    width={300}
                                    height={300}
                                    src={props.product.image}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                    alt=""
                                    loading="lazy"
                                    style={{backgroundColor:'#D9D9D9'}}
                                />
                            </div>
                        </a>
                    </div>
                    {/* /.product-loop-header */}
                    <div className="product-loop-body product-item__body">
                        <a
                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                        >
                            <h2 className="woocommerce-loop-product__title">
                                {props.product.name_en}
                            </h2>
                            <div className="product-rating">
                                <div
                                    className="star-rating"
                                    title="Rated 5 out of 5"
                                >
                                    <span style={{ width: "0%" }}>
                                        <strong className="rating">0</strong> out of
                                        5
                                    </span>
                                </div>
                                (5)
                            </div>
                            <div className="product-short-description">
                                <p>{props.product.name_en}</p>
                            </div>
                            <div className="product-sku">SKU: {props.product.sku}</div>
                        </a>
                    </div>
                    {/* /.product-loop-body */}
                    <div className="product-loop-footer product-item__footer">
                        <span className="onsale">
                            -<span className="percentage">{discount(props.product)}%</span>
                        </span>
                        <div className="price-add-to-cart">
                            <span className="price">
                                <span className="electro-price">
                                    <ins>
                                        <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                    {t('aed')}
                                                </span>
                                                {props.product.sale_price}
                                            </bdi>
                                        </span>
                                    </ins>{" "}
                                    <del>
                                        <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                    {t('aed')}
                                                </span>
                                                &nbsp;{props.product.regular_price}
                                            </bdi>
                                        </span>
                                    </del>
                                </span>
                            </span>
                            <div
                                onClick={(e) => {
                                    navigate('/cart')
                                    e.stopPropagation();
                                }}
                                className="add-to-cart-wrap added" data-toggle="tooltip" data-title="Add to cart" data-original-title="" title="" aria-describedby="tooltip485572">
                                {!inCart() && <a href="?add-to-cart=122308" data-quantity="1" className="button product_type_simple add_to_cart_button ajax_add_to_cart added" data-product_id="122308" data-product_sku="HHPT277926641234" rel="nofollow">{t('add_to_cart')}</a>}
                                {inCart() && <a className="added_to_cart wc-forward" title="" data-original-title="View cart">View cart</a>}
                            </div>

                            {(!inCart() && !_loadingCartOperation) && <div
                                onClick={(e) => {
                                    if(!_loadingCartOperation) {
                                        _setLoadingCartOperation(true);
                                        if(inCart()) {
                                            removeFromCartRequestAction({product_id: props.product.id});
                                        } else {
                                            addToCartRequestAction({quantity: 1, product: props.product});
                                        }
                                    }
                                    e.stopPropagation();
                                }}
                                className="add-to-cart-wrap"
                                data-toggle="tooltip"
                                data-title="Add to cart"
                                data-original-title=""
                                title=""
                            >
                                <a
                                    data-quantity={1}
                                    className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                    data-product_id={109059}
                                    data-product_sku="FM31321"
                                    aria-label="Add “Hiphone Luxury Iphone 13 Pro Max 24K Butterfly Artistic Edition” to your cart"
                                    rel="nofollow"
                                >
                                    {t('add_to_cart')}
                                </a>
                            </div>}

                            { _loadingCartOperation && <div style={{width: 20, height: 20}} className="loader"></div> }

                        </div>
                        {/* /.price-add-to-cart */}
                        <div className="hover-area">
                            <div className="action-buttons">
                                <div
                                    onClick={(e) => {
                                        if(!_loadingWishlistOperation) {
                                            _setLoadingWishlistOperation(true);

                                            if(inWishlist()) {
                                                removeFromWishlistRequestAction(props.product);
                                            } else {
                                                addToWishlistRequestAction(props.product);
                                            }
                                        }
                                        e.stopPropagation();
                                    }}
                                    className="yith-wcwl-add-to-wishlist add-to-wishlist-109059  wishlist-fragment on-first-load"
                                    data-fragment-ref={109059}
                                    data-fragment-options='{"base_url":"","in_default_wishlist":false,"is_single":false,"show_exists":false,"product_id":109059,"parent_product_id":109059,"product_type":"simple","show_view":false,"browse_wishlist_text":"Browse wishlist","already_in_wishslist_text":"The product is already in your wishlist!","product_added_text":"Product added!","heading_icon":"fa-heart-o","available_multi_wishlist":false,"disable_wishlist":false,"show_count":false,"ajax_loading":false,"loop_position":"after_add_to_cart","item":"add_to_wishlist"}'
                                >
                                    {/* ADD TO WISHLIST */}
                                    {!_loadingWishlistOperation && <div className="yith-wcwl-add-button">
                                        {inWishlist() && <span className="feedback"><i className="yith-wcwl-icon fa fa-heart"></i>{"   "}Product added!</span>}
                                        {!inWishlist() && <a
                                            rel="nofollow"
                                            data-product-id={109059}
                                            data-product-type="simple"
                                            data-original-product-id={109059}
                                            className="add_to_wishlist single_add_to_wishlist"
                                            data-title="Add to wishlist"
                                        >
                                            {/* <i className="yith-wcwl-icon fa fa-heart-o" />{" "} */}
                                            <span>{t('add_to_wishlist')}</span>
                                        </a>}
                                    </div>}
                                    { _loadingWishlistOperation && <div style={{width: 20, height: 20}} className="loader"></div> }

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* /.product-loop-footer */}
                </div>
                {/* /.product-inner */}
            </div>
            {/* /.product-outer */}
        </li>
    </>
}