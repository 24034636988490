import { CartModel } from "../../server/models/cart"
import { ProductModel } from "../../server/models/product"

export const AvailableCart = (props:{ cart: CartModel, onRemove: (product: ProductModel) => any })=>{
    const product = props.cart.product;
    const quantity = props.cart.quantity;

    if(!props.cart.product) {
        return <></>
    }

    return(
        <ul key={`cart-${product.id}`} className="woocommerce-mini-cart cart_list product_list_widget ">
                <li className="woocommerce-mini-cart-item mini_cart_item">
                    <a
                    onClick={() => {
                        props.onRemove(product)
                    }}
                    className="remove remove_from_cart_button"
                    aria-label="Remove this item"
                    data-cart_item_key="e3567be83ffd15a9fd5bf2619aa99482"
                    data-product_sku="ISQC3.03IN1C"
                    >
                    ×
                    </a>{" "}
                    <a href={`https://hiphonetelecom.com/product/${product?.slug}`}>
                    <img
                        width={300}
                        height={300}
                        src={props.cart.product.image}
                        className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                        alt=""
                        loading="lazy"
                        
                        sizes="(max-width: 300px) 100vw, 300px"
                    />
                    {product.name_en}  &nbsp;{" "}
                    </a>
                    <span className="quantity">
                    {quantity} ×{" "}
                    <span className="woocommerce-Price-amount amount">
                        
                        <span className="woocommerce-Price-currencySymbol">AED</span>
                        &nbsp;{product.sale_price}
                        
                    </span>
                    </span>
                </li>
                <p className="woocommerce-mini-cart__total total">
                    <strong>Subtotal:</strong>{" "}
                    <span className="woocommerce-Price-amount amount">
                        <bdi>
                        <span className="woocommerce-Price-currencySymbol">AED</span>
                        &nbsp;{product.sale_price}
                        </bdi>
                    </span>
                </p>
        </ul>
        

    )
}