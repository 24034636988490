import { Api } from './Api';
import { ADD_TO_CART_URL, CALCULATE_URL, CART_URL, CHECKOUT_STATUS_URL, CHECKOUT_URL, OFFERS_URL, PAYMENT_LINK_URL, REMOVE_FROM_CART_URL, UPDATE_CART_URL } from '../../configs';
import { AddToCartPayload } from '../payload/add_to_cart';
import { CalculatePayload } from '../payload/calculate';
import { CheckoutPayload } from '../payload/checkout';


export class OffersApis {
  static offers(): ReturnType<typeof Api.get> {
    return Api.get(OFFERS_URL);
  }
}