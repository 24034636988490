import { createReducer } from 'deox';
import { produce } from 'immer';
import { CartActions } from './actions';
import { CartTypes } from './types';

const initialState: CartTypes = {
  loading: false,
  localUpdatedCart: [],
  loadingCalculate: false,
  loadingCart: false,
  loadingAddToCart: false,
  loadingRemoveFromCart: false,
  loadingUpdateCart: false,
  calculate: {
    message: undefined,
    subtotal: '',
    discount: undefined,
    item_total: undefined,
    delivery_fee: '',
    total: '',
    description: ''
  },
  coupon: '',
};

export const cartReducer = createReducer(initialState, handleAction => [
  // Cart
  handleAction(CartActions.cart.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingCart = true;
    }),
  ),
  handleAction(CartActions.cart.success, (state) =>
    produce(state, draft => {
      draft.loadingCart = false;
    }),
  ),
  handleAction(CartActions.cart.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingCart = false;
    }),
  ),

  // Add
  handleAction(CartActions.add.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingAddToCart = true;
    }),
  ),
  handleAction(CartActions.add.success, (state) =>
    produce(state, draft => {
      draft.loadingAddToCart = false;
    }),
  ),
  handleAction(CartActions.add.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingAddToCart = false;
    }),
  ),

  // Remove
  handleAction(CartActions.remove.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingRemoveFromCart = true;
    }),
  ),
  handleAction(CartActions.remove.success, (state) =>
    produce(state, draft => {
      draft.loadingRemoveFromCart = false;
    }),
  ),
  handleAction(CartActions.remove.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingRemoveFromCart = false;
    }),
  ),

  // Update
  handleAction(CartActions.update.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingUpdateCart = true;
    }),
  ),
  handleAction(CartActions.update.success, (state) =>
    produce(state, draft => {
      draft.loadingUpdateCart = false;
      draft.localUpdatedCart = []
    }),
  ),
  handleAction(CartActions.update.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingUpdateCart = false;
    }),
  ),

  // Update 'local updated cart'
  handleAction(CartActions.localUpdatedCart.set, (state, { payload }) =>
    produce(state, draft => {
      // Search for the item
      var _cart = (state.localUpdatedCart ?? []).slice(0)
      console.log('cart cache')
      console.log(_cart)
      var _index: number = -1;
      var  remove = false;
      payload.cart.forEach((cart, index) => {
        console.log(`${payload.item.product.id} - ${cart.product.id} : ${payload.item.product.id === cart.product.id}`)
        if (payload.item.product.id === cart.product.id) {
          console.log(`payload quantity? : ${payload.item.quantity}`)
          console.log(`local quantity? : ${cart.quantity}`)
          console.log(`remove? : ${payload.item.quantity == cart.quantity}`)
          if(payload.item.quantity == cart.quantity) {
            console.log(`remove? : ${remove}`)
            remove = true;
          }
          _index = index;
        }
      })

      if (_index > -1) {
        // Update
        if(remove) {
          _cart = _cart.filter(item => (payload.item.product.id != item.product.id));
        } else {
          _cart[_index] = payload.item
        }
      } else {
        // Add
        console.log(`push index: ${_index}`)
        _cart.push(payload.item)
      }

      console.log('local updated cart')
      console.log(_cart)

      draft.localUpdatedCart = _cart;
    }),
  ),

  // Clear 'local updated cart'
  handleAction(CartActions.localUpdatedCart.clear, (state) =>
    produce(state, draft => {
      draft.localUpdatedCart = [];
    }),
  ),


  // Calculate
  handleAction(CartActions.calculate.request, (state) =>
    produce(state, draft => {
      draft.loadingCalculate = true;
    }),
  ),
  handleAction(CartActions.calculate.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingCalculate = false;
      draft.calculate = payload
    }),
  ),
  handleAction(CartActions.calculate.fail, (state) =>
    produce(state, draft => {
      draft.loadingCalculate = false;
    }),
  ),

  // Coupon
  handleAction(CartActions.coupon.apply, (state, {payload}) =>
    produce(state, draft => {
      draft.coupon = payload;
    }),
  ),
  handleAction(CartActions.coupon.clear, (state) =>
    produce(state, draft => {
      draft.coupon = '';
    }),
  ),
]);
